import configuration from "../../../configuration";
import "./tracking.css";
import { showModal } from '../../../core/modal/modal';
import { hideOverlay } from '../../../core/overlay/overlay';

let startedTracking = false;
let askedForPermission = false;

const trackingFunction = () => {
	const _paq = (window as any)._paq = (window as any)._paq || [];
	_paq.push(['trackPageView']);
	_paq.push(['enableLinkTracking']);
	const u = "https://stats.slpb.de/";
	_paq.push(['setTrackerUrl', u + 'matomo.php']);
	_paq.push(['setSiteId', '10']);
	const g = document.createElement('script');
	g.src = u + 'matomo.js';
	g.type = 'text/javascript';
	g.async = true;
	document.body.append(g);
};

const maybeStartTracking = () => {
	if (startedTracking) {
		return;
	}
	if (localStorage.getItem("allowed-matomo-tracking") !== "true") {
		return;
	}
	startedTracking = true;
	trackingFunction();
};

const maybeAskForPermission = () => {
	if (askedForPermission) {
		return;
	}
	if (localStorage.getItem("allowed-matomo-tracking")) {
		return;
	}
	askedForPermission = true;
	const modal = showModal(`<h3>Privatsphäre-Einstellungen</h3>
		<p>Diese Webseite verwendet zum Website-Tracking eine Matomo-Instanz der Sächsischen Landeszentrale für politische Bildung, um ihre Dienste bereitzustellen und kontinuierlich zu verbessern.</p>
		<p>Wenn Sie dem zustimmen, klicken Sie bitte auf Akzeptieren.</p>
		<div class="button-wrap">
			<button class="dlw accept">Akzeptieren</button>
			<button class="dlw deny">Ablehnen</button>
		</div>
		`);

	const accept = modal.querySelector(".accept");
	const deny = modal.querySelector(".deny");
	if (!accept || !deny) {
		return;
	}
	accept.addEventListener("click", () => {
		localStorage.setItem("allowed-matomo-tracking", "true");
		maybeStartTracking();
		hideOverlay();
	});
	deny.addEventListener("click", () => {
		localStorage.setItem("allowed-matomo-tracking", "false");
		hideOverlay();
	});
};

export const initTracking = () => {
	if (configuration.target !== "weltreligionen") {
		return;
	}
	maybeStartTracking();
	maybeAskForPermission();
};
setTimeout(initTracking, 0);
